export const bussinesHoursData = [
    {
        id:1,
        dayUA:"Понеділок",
        dayEN:"Monday",
        time:"9:00 - 17:30"
    },

    {
        id:2,
        dayUA:"Вівторок",
        dayEN:"Tuesday",
        time:"9:00 - 17:30"
    },

    {
        id:3,
        dayUA:"Середа",
        dayEN:"Wednesday",
        time:"9:00 - 17:30"
    },

    {
        id:4,
        dayUA:"Четвер",
        dayEN:"Thursday",
        time:"9:00 - 17:30"
    },
    {
        id:5,
        dayUA:"П'ятниця",
        dayEN:"Friday",
        time:"9:00 - 17:30"
    },

    {
        id:6,
        dayUA:"Субота",
        dayEN:"Saturday",
        time:""
    },

    {
        id:7,
        dayUA:"Неділя",
        dayEN:"Sunday",
        time:""
    }
];