export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const REGESTRATION_ROUTE = '/regestration'
export const HOME_ROUTE = '/'
export const ABOUT_ROUTE = '/about'
export const ANNOUNSMENT_ROUTE = '/announsment'
export const CHUMMERY_ROUTE = '/chummery'
export const CONTACTS_ROUTE = '/contacts'
export const DOCUMENTS_ROUTE = '/docs'
export const HAVE_IDEA_ROUTE = '/idea'
export const INFORMATION_ROUTE = '/info'
export const NEWS_ROUTE = '/news'
export const POLL_ROUTE = '/poll'
export const PROFKOM_STRUCTURE_ROUTE = '/structure'
export const PROFKOM_TEAM_ROUTE = '/pteam'
export const PROGRAMS_ROUTE = '/programs'
export const QA_ROUTE = '/qa'
export const RATING_ROUTE = '/rating'
export const TRUST_BOX_ROUTE = '/trustbox'
export const SUPERADMIN_ROUTE = '/superadmin'
export const ADDITIONAL_POINTS_ROUTE = '/additionalPoints'