export const polls = [
    {
        id:1,
        title:'edit_teamMembers',
        status:"active",
        department:"uviver"
    },
    
    {
        id:2,
        title:'edit_teamMembers',
        status:"disactive",
        department:"bio"
    },


    {
        id:3,
        title:'edit_teamMembers',
        status:"active",
        department:"uviver"
    },


    {
        id:4,
        title:'edit_teamMembers',
        status:"disactive",
        department:"fdfd"
    },


    {
        title:'edit_teamMembers',
        status:"active",
        department:"uviver"
    },


    {
        id:6,
        title:'edit_teamMembers',
        status:"active",
        department:"ier"
    },


    {
        id:7,
        title:'edit_teamMembers',
        status:"active",
        department:"uviver"
    },


    {
        id:8,
        title:'edit_teamMembers',
        status:"disactive",
        department:"math"
    }

];